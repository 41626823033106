import styled from 'styled-components';

import StampSrc from '../../images/stamp-dark.svg';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  background-color: #141f35;
  background-image: url(${StampSrc});
  background-repeat: no-repeat;
  z-index: 99;

  @media (max-width: 1023px) {
    padding: 48px 0;
    background-position-x: calc(100% + 65px);
    background-position-y: calc(100% + 25px);
    background-size: 140px;
  }

  @media (min-width: 1024px) {
    padding-top: 100px;
    padding-bottom: 130px;
    background-position: 60% 220px;
    background-size: 286px;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1132px;
  padding: 0 24px;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

export const Info = styled.div`
  max-width: 625px;
`;

export const Title = styled.h3`
  color: #d1914c;
  font-family: 'GreatWestern';
  letter-spacing: 0.05em;

  @media (max-width: 1023px) {
    font-size: 24px;
    line-height: 30px;
  }

  @media (min-width: 1024px) {
    font-size: 32px;
    line-height: 38px;
  }
`;

export const Desc = styled.p`
  color: #fff;

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 24px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 35px;
  }
`;

export const Form = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: center;
  position: relative;

  @media (max-width: 1023px) {
    margin-top: 30px;
  }

  @media (min-width: 1024px) {
    margin-left: 50px;
  }
`;
