import React, { useEffect, useState } from 'react';

import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import HeroTheLegend from '../components/HeroTheLegend/HeroTheLegend';
import Recipe from '../components/Recipe/Recipe';
import Footer from '../components/Footer/Footer';
import Subscribe from '../components/Subscribe/Subscribe';
import Instagram from '../components/Instagram/Instagram';
import BgDesktopSrc from '../images/hero-recipe-bg-desktop.jpg';
import recipes from '../components/Recipes/config';
import SEO from '../components/SEO/SEO';
import CookieDisclaimer from '../components/CookieDisclaimer/CookieDisclaimer';

const RecipePage = ({ location }) => {
  const [recipe, setRecipe] = useState(null);
  const [nextRecipeIndex, setNextRecipeIndex] = useState(1);
  const [previousRecipeIndex, setPreviousRecipeIndex] = useState(
    recipes.length - 1,
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search.substring(1));
    const id = params.get('id');
    const recipeIndex = recipes.findIndex(itm => itm.id === id);
    const newRecipe = recipeIndex == null ? null : recipes[recipeIndex];
    const newNextRecipeIndex =
      recipeIndex < recipes.length - 1 ? recipeIndex + 1 : 0;
    const newPreviousRecipeIndex =
      recipeIndex === 0 ? recipes.length - 1 : recipeIndex - 1;
    setNextRecipeIndex(newNextRecipeIndex);
    setPreviousRecipeIndex(newPreviousRecipeIndex);
    setRecipe(newRecipe);
  }, [(location || {}).search]);

  return (
    <>
      <SEO pageName="recipe" />
      <div
        style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}
      >
        <Header />
        <main id="main" tabIndex="-1">
          {recipe == null && (
            <HeroTheLegend title="Page not found" isNotFound />
          )}
          {recipe != null && (
            <Hero
              img={{ mobile: null, desktop: recipe.image }}
              title={recipe.shortTitle}
            />
          )}
          {recipe != null && (
            <Recipe
              recipe={recipe}
              nextRecipeId={recipes?.[nextRecipeIndex].id}
              previousRecipeId={recipes?.[previousRecipeIndex].id}
            />
          )}

          <Subscribe />
          <Instagram />
        </main>
        <Footer />
      </div>
      <CookieDisclaimer />
    </>
  );
};

export default RecipePage;
