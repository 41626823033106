import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 82px;
  background-color: #e9dfd0;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1225px;
`;

export const ReturnLink = styled.div`
  display: flex;
  padding: 0 24px;
  margin-bottom: 20px;
  color: #141f35;
  font-family: "GreatWestern";
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    justify-content: center;
    font-size: 12px;
    line-height: 24px;
  }

  @media (min-width: 1024px) {
    justify-content: flex-end;
    font-size: 14px;
    line-height: 50px;
  }
`;

export const Body = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const StampImg = styled.img`
  position: absolute;
  bottom: -140px;
  left: -10px;
`;

export const PrevNext = styled.a`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50px;
  height: 50px;

  @media (max-width: 1023px) {
    display: none;
  }
  
  &:focus {
   border: 1px solid rgb(20, 31, 53);
  }
`;

export const Content = styled.div`
  position: relative;

  @media (max-width: 1023px) {
    padding: 0 24px;
  }

  @media (min-width: 1024px) {
    padding: 0 60px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 16px;
  color: #141f35;
  font-family: "GreatWestern";
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (min-width: 1024px) {
    font-size: 32px;
    line-height: 50px;
  }

  &.--subtitle {
    color: #d79d5c;
  }
`;

export const Desc = styled.div`
  margin-bottom: 32px;
  color: #141f35;

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 24px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 30px;
  }

  p {
    margin-bottom: 16px;
  }
`;

export const List = styled.ul`
  padding-left: 20px;
  color: #141f35;
  list-style: disc;

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 24px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 30px;
  }
`;

export const Step = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
`;

export const StepImg = styled.img`
  max-width: 430px;
  margin-right: 38px;
  object-fit: contain;
`;

export const StepBody = styled.div`
  flex-grow: 1;
`;

export const StepDesc = styled.div`
  width: 100%;
  color: #141f35;

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 24px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 30px;
  }
`;
